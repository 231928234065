import { DeploymentTestResults } from "../entities"

export const defaultTestResults: DeploymentTestResults = {
  testReportUrl: "",
  testPipelineUrl: "",
  testReportSummary: {
    reportName: "Allure Report",
    testRuns: [],
    statistic: {
      failed: 0,
      broken: 0,
      skipped: 0,
      passed: 0,
      unknown: 0,
      total: 0,
    },
    time: {
      start: 0,
      stop: 0,
      duration: 0,
      minDuration: 0,
      maxDuration: 0,
      sumDuration: 0,
    },
    linkinator: {
      broken: {
        total: 0,
        links: [],
      },
      success: {
        total: 0,
      },
      skipped: {
        total: 0,
      },
    },
  },
}
