import { Flex, Text, VStack, Tooltip } from "@chakra-ui/react"
import {
  defaultTestResults,
  DeploymentTestResults as DeploymentTestResultsData,
} from "@jackfruit/common"
import React from "react"

interface Props {
  testResults: DeploymentTestResultsData
  width?: string
}

const DeploymentLinkinatorResults: React.FC<Props> = ({
  testResults,
  width = "150px",
}) => {
  const result = testResults || defaultTestResults

  // If there are no linkinator results from old deployment version
  if (!result.testReportSummary?.linkinator) {
    return null
  }

  const {
    testReportSummary: {
      linkinator: {
        broken: { total: totalBroken = 0 } = {},
        success: { total: totalSuccess = 0 } = {},
        skipped: { total: totalSkipped = 0 } = {},
      },
    },
  } = result

  const total = totalBroken + totalSkipped + totalSuccess

  const ratioPassed = totalSuccess / total
  const ratioFailed = totalBroken / total
  const ratioSkipped = totalSkipped / total

  return (
    <VStack spacing={1} alignItems="flex-start" mt={2}>
      {total > 0 ? (
        <>
          <Text fontSize="xs" fontWeight="semibold" color="gray.600">
            Linkinator
          </Text>

          <Flex
            w={width}
            h="20px"
            borderRadius="md"
            overflow="hidden"
            bg="blackAlpha.100"
          >
            <Tooltip
              label={`${totalSuccess} successful links`}
              hasArrow
              placement="top"
            >
              <Flex
                flex={ratioPassed}
                bg="green.300"
                justifyContent="center"
                px={1}
              >
                <Text fontSize="xs" fontWeight="semibold" color="white">
                  {totalSuccess}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={`${totalBroken} broken links`}
              hasArrow
              placement="top"
            >
              <Flex
                flex={ratioFailed}
                bg="red.400"
                justifyContent="center"
                px={1}
              >
                <Text fontSize="xs" fontWeight="semibold" color="white">
                  {totalBroken}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={`${totalSkipped} skipped links`}
              hasArrow
              placement="top"
            >
              <Flex flex={ratioSkipped} justifyContent="center" px={1}>
                <Text fontSize="xs" fontWeight="semibold" color="gray.400">
                  {totalSkipped}
                </Text>
              </Flex>
            </Tooltip>
          </Flex>
        </>
      ) : (
        <Flex h="20px" />
      )}
    </VStack>
  )
}

export default DeploymentLinkinatorResults
