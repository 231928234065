import {
  Badge,
  Box,
  HStack,
  IconButton,
  Link as ChakraLink,
  Link,
  Spinner,
  Text,
  VStack,
} from "@chakra-ui/react"
import {
  AppEntity,
  DeploymentEntity,
  DeploymentType,
  EntityId,
} from "@jackfruit/common"
import DeploymentLinkinatorResults from "components/DeploymentLinkinatorResults"
import DeploymentStatusBadge from "components/DeploymentStatusBadge"
import DeploymentTestResults from "components/DeploymentTestResults"
import Table, { ColumnDefinition, RowDefinition } from "components/Table"
import TableDeployedApplicationName from "components/TableDeployedApplicationName"
import TableRowActions from "components/TableRowActions"
import TableUserCell from "components/TableUserCell"
import format from "date-fns/format"
import formatDistanceToNowStrict from "date-fns/formatDistanceToNowStrict"
import { useEntityList } from "hooks/useEntityList"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { BiBarChartSquare } from "react-icons/bi"
import { FiExternalLink, FiEye } from "react-icons/fi"
import { GrPowerCycle } from "react-icons/gr"
import { Link as ReactRouterLink } from "react-router-dom"
import DeploymentAppFilter from "screens/deployments/DeploymentAppFilter"

interface Props {
  appId?: EntityId
  deploymentTypes: DeploymentType[]
}

const DeploymentsTable: React.FC<Props> = ({ appId, deploymentTypes }) => {
  const defaultValues = { id: appId }
  const methods = useForm<AppEntity>({ defaultValues })
  const { watch } = methods

  const selectedAppId = watch("id") || undefined

  const query = {
    appId: selectedAppId,
    type: { $in: deploymentTypes },
    $select: [
      "id",
      "appId",
      "createdAt",
      "userId",
      "type",
      "status",
      "comment",
      "gitReference",
      "url",
      "testResults",
      "testTags",
      "testPipelineUrl",
    ],
  }

  return (
    <FormProvider {...methods}>
      <Table
        resource="deployments"
        query={query}
        rowDefinition={rowDefinition}
        columns={columns}
        orderBy="asc"
        options={{
          refetchInterval: 5000,
        }}
        limit={100}
      />
    </FormProvider>
  )
}

export default DeploymentsTable

interface FilterProps {}

const Filter: React.FC<FilterProps> = () => {
  const {
    data: { data },
    isLoading,
  } = useEntityList<AppEntity>("apps", {
    query: {
      $limit: 1000,
      $sort: {
        name: 1,
      },
    },
  })

  return isLoading ? (
    <Spinner color="gray.400" />
  ) : (
    <DeploymentAppFilter name="id" apps={data} />
  )
}

interface CellProps {
  children: React.ReactNode
}

const Cell: React.FC<CellProps> = ({ children }) => {
  return (
    <Box py={3} borderWidth={0}>
      {children}
    </Box>
  )
}

const rowDefinition: RowDefinition = {
  getProperties: (item: any) => {
    return { verticalAlign: "top" }
  },
}

const columns: ColumnDefinition[] = [
  {
    header: "Id",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <Link
          fontSize="sm"
          as={ReactRouterLink}
          to={`/admin/apps/${deployment.appId}/deployments/${deployment.id}`}
        >
          #{deployment.id}
        </Link>
      </Cell>
    ),
  },
  {
    header: "Created At",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <VStack spacing={1} alignItems="flex-start">
          <Text fontSize="md">
            {formatDistanceToNowStrict(new Date(deployment.createdAt!))} ago
          </Text>
          <Text fontSize="xs" color="gray.400">
            {format(new Date(deployment.createdAt!), "yyyy-MM-dd HH:mm:ss")}
          </Text>
        </VStack>
      </Cell>
    ),
  },
  {
    header: "Application",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <TableDeployedApplicationName deployment={deployment} />
      </Cell>
    ),
    customFilter: (_item?: any) => <Filter />,
  },
  {
    header: "Status",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <DeploymentStatusBadge status={deployment.status} />
      </Cell>
    ),
  },
  {
    header: "Build",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <Text fontSize="sm" colorScheme="blue">
          {deployment.gitReference}-{deployment.id}
        </Text>
      </Cell>
    ),
  },
  {
    header: "Deployed By",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <TableUserCell id={deployment.userId} />
      </Cell>
    ),
  },
  {
    header: "Test Progress",
    render: (deployment: DeploymentEntity) => (
      <Cell>
        <DeploymentTestResults testResults={deployment.testResults} />
        <DeploymentLinkinatorResults testResults={deployment.testResults} />
      </Cell>
    ),
  },
  {
    header: "Test Tags",
    render: (deployment: DeploymentEntity) => {
      const testTags = deployment.testTags ? deployment.testTags.split("|") : []
      return (
        <Cell>
          <VStack spacing={1} alignItems="flex-start">
            {testTags.map((tag, index) => (
              <Badge key={index} colorScheme="purple">
                {tag}
              </Badge>
            ))}
          </VStack>
        </Cell>
      )
    },
  },

  {
    header: "Actions",
    accessor: "actions",
    render: (deployment: DeploymentEntity) => {
      const hasTestResults = Boolean(deployment.testResults)
      const testReportUrl = deployment.testResults?.testReportUrl ?? ""
      const hasTestPipelineResult = Boolean(deployment.testPipelineUrl)
      const testPipelineUrl = deployment.testPipelineUrl ?? ""

      return (
        <Cell>
          <HStack>
            <TableRowActions
              resource="deployments"
              entity={deployment}
              canView={false}
              canEdit={false}
              canDelete={false}
            >
              <IconButton
                aria-label="view"
                icon={<FiEye />}
                as={ReactRouterLink}
                to={`/admin/apps/${deployment.appId}/deployments/${deployment.id}`}
                size="md"
                rounded="full"
                variant="ghost"
                colorScheme="gray"
              />
              <IconButton
                isDisabled={!hasTestPipelineResult}
                aria-label="Test Automation"
                size="md"
                rounded="full"
                variant="ghost"
                colorScheme="gray"
                icon={<GrPowerCycle />}
                onClick={() => window.open(testPipelineUrl, "_blank")}
              />
              <IconButton
                isDisabled={!hasTestResults}
                aria-label="Test Report"
                size="md"
                rounded="full"
                variant="ghost"
                icon={<BiBarChartSquare />}
                ml={2}
                onClick={() => window.open(testReportUrl, "_blank")}
              />
              <IconButton
                aria-label="open-url"
                as={ChakraLink}
                rounded="full"
                variant="ghost"
                colorScheme="red"
                href={deployment.url}
                isExternal
                icon={<FiExternalLink />}
              />
            </TableRowActions>
          </HStack>
        </Cell>
      )
    },
  },
]
