import { Flex, Text, VStack, Tooltip } from "@chakra-ui/react"
import {
  defaultTestResults,
  DeploymentTestResults as DeploymentTestResultsData,
} from "@jackfruit/common"
import { intervalToDuration } from "date-fns"
import React from "react"

interface Props {
  testResults: DeploymentTestResultsData
  width?: string
}

const DeploymentTestResults: React.FC<Props> = ({
  testResults,
  width = "150px",
}) => {
  const result = testResults || defaultTestResults

  const {
    broken = 0,
    failed = 0,
    passed = 0,
    skipped = 0,
    total = 0,
    unknown = 0,
  } = result?.testReportSummary?.statistic

  const durationInMilliseconds = result.testReportSummary.time?.duration ?? 0

  const duration = intervalToDuration({
    start: 0,
    end: durationInMilliseconds,
  })

  const failedOrBroken = failed + broken
  const skippedOrUnknown = skipped + unknown

  const ratioPassed = passed / total
  const ratioFailed = failedOrBroken / total
  const ratioSkipped = skippedOrUnknown / total

  return (
    <VStack spacing={1} alignItems="flex-start">
      {total > 0 ? (
        <>
          <Text fontSize="xs" fontWeight="semibold" color="gray.600">
            Playwright
          </Text>
          <Flex
            w={width}
            h="20px"
            borderRadius="md"
            overflow="hidden"
            bg="blackAlpha.100"
          >
            <Tooltip label={`${passed} passed tests`} hasArrow placement="top">
              <Flex
                flex={ratioPassed}
                bg="green.300"
                justifyContent="center"
                px={1}
              >
                <Text fontSize="xs" fontWeight="semibold" color="white">
                  {passed}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={`${failedOrBroken} failed/broken tests (${failed} failed, ${broken} broken)`}
              hasArrow
              placement="top"
            >
              <Flex
                flex={ratioFailed}
                bg="red.400"
                justifyContent="center"
                px={1}
              >
                <Text fontSize="xs" fontWeight="semibold" color="white">
                  {failedOrBroken}
                </Text>
              </Flex>
            </Tooltip>

            <Tooltip
              label={`${skippedOrUnknown} skipped/unknown tests (${skipped} skipped, ${unknown} unknown)`}
              hasArrow
              placement="top"
            >
              <Flex flex={ratioSkipped} justifyContent="center" px={1}>
                <Text fontSize="xs" fontWeight="semibold" color="gray.400">
                  {skippedOrUnknown}
                </Text>
              </Flex>
            </Tooltip>
          </Flex>

          <Text fontSize="xs" fontWeight="semibold" color="gray.400">
            {duration.hours}h {duration.minutes}m {duration.seconds}s
          </Text>
        </>
      ) : (
        <Flex h="20px" />
      )}
    </VStack>
  )
}

export default DeploymentTestResults
