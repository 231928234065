import { VStack } from "@chakra-ui/layout"
import { DeploymentEntity, EntityId } from "@jackfruit/common"
import AceEditorEnhanced from "components/AceEditorEnhanced"
import DeploymentLinkinatorResults from "components/DeploymentLinkinatorResults"
import DeploymentTestResults from "components/DeploymentTestResults"
import { useEntity } from "hooks/useEntity"
import React from "react"

interface Props {
  deploymentId: EntityId
  testResultType?: "playwright" | "linkinator"
}

const DeploymentTestResultsContainer: React.FC<Props> = ({
  deploymentId,
  testResultType = "playwright",
}) => {
  const { data: entity, isLoading } = useEntity<DeploymentEntity>(
    "deployments",
    deploymentId,
    {
      refetchInterval: 7000,
    },
    {
      query: {
        $select: ["id", "testResults"],
      },
    }
  )

  const showPlaywright = testResultType === "playwright"
  const showLinkinator = testResultType === "linkinator" && entity?.testResults

  if (isLoading) {
    return null
  }

  return (
    <VStack alignItems="stretch" spacing={4} mt={3}>
      {showPlaywright && (
        <>
          <DeploymentTestResults
            testResults={entity.testResults}
            width="full"
          />

          <AceEditorEnhanced
            height="600px"
            value={JSON.stringify(entity.testResults, undefined, 2)}
            mode="json"
            name="config"
          />
        </>
      )}
      {showLinkinator && (
        <>
          <DeploymentLinkinatorResults
            testResults={entity.testResults}
            width="full"
          />

          <AceEditorEnhanced
            height="600px"
            value={JSON.stringify(
              entity.testResults.testReportSummary.linkinator,
              undefined,
              2
            )}
            mode="json"
            name="config"
          />
        </>
      )}
    </VStack>
  )
}

export default DeploymentTestResultsContainer
