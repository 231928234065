import { AppEntity } from "@jackfruit/common"
import "ace-builds"
import "ace-builds/webpack-resolver"
import FormContainer from "components/FormContainer"
import FormSectionHeader from "components/FormSectionHeader"
import FormActions from "forms/FormActions"
import React from "react"
import { FormProvider, useForm } from "react-hook-form"
import { removeEmptyObjects } from "utils/helpers"
import LocaleNavigationEditor from "../locale-navigation/LocaleNavigationEditor"

interface Props {
  entity?: AppEntity
  onSubmit: (data: any) => void
  isLoading: boolean
}

const AppBrandingLocalizationAdditionalLocaleUrlsForm: React.FC<Props> = ({
  entity,
  onSubmit,
  isLoading,
}) => {
  const defaultValues: any = {
    ...entity,
  }

  const methods = useForm({
    defaultValues,
  })

  const { handleSubmit } = methods

  const enhancedOnSubmit = (data: Pick<AppEntity, "additionalLocaleUrls">) => {
    const { additionalLocaleUrls = [] } = data

    const prunedAdditionalLocaleUrls = removeEmptyObjects(additionalLocaleUrls)

    onSubmit({
      additionalLocaleUrls: JSON.stringify(prunedAdditionalLocaleUrls),
    })
  }

  return (
    <FormProvider {...methods}>
      <FormContainer onSubmit={handleSubmit(enhancedOnSubmit)}>
        <FormSectionHeader>Additional Locale Urls</FormSectionHeader>
        <LocaleNavigationEditor />
        <FormActions canCancel={false} isLoading={isLoading} entity={entity} />
      </FormContainer>
    </FormProvider>
  )
}

export default AppBrandingLocalizationAdditionalLocaleUrlsForm
